@charset 'utf-8';

html {
  box-sizing: border-box;
  overflow-x: hidden;
  scroll-behavior: smooth;
  *, *:before, *:after {
    box-sizing: inherit;
  }
}

body {
  overflow-x: hidden;
  background-color: #{$background-color};
  @media #{$medium-and-down} {
    padding-top: 40px;
  }
}

a {
  color: inherit;
  .primary-text &,
  &.primary-text {
    &:hover {
      color: #{$primary-color-darken};
    }
  }
  .secondary-text &,
  &.secondary-text {
    &:hover {
      color: #{$secondary-color-darken};
    }
  }
  .accent-text &,
  &.accent-text {
    &:hover {
      color: #{$accent-color-darken};
    }
  }
  &.tel-important {
    font-size: 24px;
    font-weight: 600;
  }
}

main p {
  margin-bottom: 1rem;
  a {
    text-decoration: underline;
    color: #{$secondary-color};
    &:hover {
      text-decoration: none;
      color: #{$secondary-color-darken};
    }
  }
}

address {
  font-style: normal;
}

.index-page {
  .no-index{
      display: none;
  }
}

.brand {
  a {
    display: inline-block;
    width: max-content;
    max-width: 100%;
    svg {
      display: block;
    }
    .flex-row > .col:last-child {
      text-align: left;
    }
  }
  body > header & {
    a {
      img.footer-only {
        display: none;
      }
    }
  }
  body > footer & {
    a {
      img.header-only {
        display: none;
      }
    }
  }
}

// Tables
// =============================================================
table {
  td {
    vertical-align: baseline;
    padding: 3px 10px 3px 0px;
  }
  &.horaires {
    .today {
      font-weight: 700;
    }
  }
}

// Divider
// =============================================================
.divider {
    height: 1px;
    overflow: hidden;
    background-color: #{$secondary-color};
    .white-text & {
      background-color: #FFFFFF;
    }
  }

// Images
// =============================================================
img.responsive-img,
video.responsive-video {
  max-width: 100%;
  height: auto;
}

// Google Maps
// =============================================================
.map-container {
  iframe {
    display: block;
    width: 100%;
  }
}
#encart-infos {
  .s-align-items-stretch,
  .m-align-items-stretch,
  .l-align-items-stretch,
  .xl-align-items-stretch {
    .col {
      &.m1, &.m2, &.m3, &.m4, &.m5, &.m6, &.m7, &.m8, &.m9, &.m10, &.m11 {
        @media #{$medium-and-up} {
          .map-container {
            height: 100%;
            iframe {
              height: 100%;
              min-height: 300px;
            }
          }
        }
      }
      &.l1, &.l2, &.l3, &.l4, &.l5, &.l6, &.l7, &.l8, &.l9, &.l10, &.l11 {
        @media #{$large-and-up} {
          .map-container {
            height: 100%;
            iframe {
              height: 100%;
              min-height: 300px;
            }
          }
        }
      }
      &.xl1, &.xl2, &.xl3, &.xl4, &.xl5, &.xl6, &.xl7, &.xl8, &.xl9, &.xl10, &.xl11 {
        @media #{$extra-large-and-up} {
          .map-container {
            height: 100%;
            iframe {
              height: 100%;
              min-height: 300px;
            }
          }
        }
      }
    }
  }
}

// Responsive Videos
// =============================================================
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe, object, embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}

// Responsive Images
//==============================================================
@mixin img-size-cover($size) {
  display: block;
  width: auto !important;
  max-width: none !important;
  min-width: 1000%;
  height: auto;
  min-height: 1000%;
  margin: auto;
  position: absolute;
  top: -10000%;
  right: -10000%;
  bottom: -10000%;
  left: -10000%;
  transform: scale($size / 1000);
}

.image-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  &.s-fit-content {
    height: 100%;
    img {
      @include img-size-cover(100);
    }
  }
  &.m-fit-content {
    @media #{$medium-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.l-fit-content {
    @media #{$large-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.xl-fit-content {
    @media #{$extra-large-and-up} {
      height: 100%;
      img {
        @include img-size-cover(100);
      }
    }
  }
  &.circle {
    &::after {
      display: block;
      width: 100%;
      height: 0 !important;
      padding-bottom: 100%;
      content: '';
    }
    img {
      @include img-size-cover(100);
    }
  }
}

// Icons style
// =============================================================
.material-icons {
  font-family: 'Material Icons' !important;
  line-height: 1;
  vertical-align: middle;

  &.left {
    float: left;
    margin-right: 1rem;
  }
  &.right {
    float: right;
    margin-left: 1rem;
  }
  &.tiny {
    font-size: 1rem;
  }
  &.small {
    font-size: 2rem;
  }
  &.medium {
    font-size: 4rem;
  }
  &.large {
    font-size: 6rem;
  }
  &.primary, &.secondary, &.accent, &.circle {
    width: 1.75em;
    height: 1.75em;
    line-height: 1.75em;
    text-align: center;
  }
  &.primary, &.secondary, &.accent {
    color: #FFFFFF;
    border-radius: #{$button-radius};
  }
  &.circle {
    border-radius: 50%;
  }
  &.outlined {
    border: solid 1px;
    background-color: transparent !important;
    &.primary {
      color: #{$primary-color};
    }
    &.secondary {
      color: #{$secondary-color};
    }
    &.accent {
      color: #{$accent-color};
    }
  }
  [class*=btn] & {
    margin-right: .5rem;
  }
}

// Numéros de téléphone
// =============================================================
.tel {
  white-space: nowrap;
  @media #{$medium-and-up} {
    pointer-events: none;
    cursor: text;
  }
}

// Fil d'Ariane
// =============================================================
#fil_ariane {
  a {
    text-decoration: underline;
    color: #{$secondary-color};
    &:hover {
      text-decoration: none;
      color: #{$secondary-color-darken};
    }
  }
  .first {
    display: inline;
  }
  @media #{$large-and-up} {
    font-size: .875em;
  }
}


// Utility Classes
// =============================================================
.hide {
    display: none !important;
  }


// List
// ======================================
ul {
  margin-left: 40px;
  &.list-nostyle {
    list-style-type: none;
    margin: 0;
  }
  &.inline {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    margin: {
      right: (-1 * $gutter-width / 2);
      left: (-1 * $gutter-width / 2);
    }
    >
      li {
        padding: 0 $gutter-width / 2;
        list-style: none;
      }
  }
  li {
    padding: .25em 0;
  }
}


// Text Align
// ======================================
.left-align {
    text-align: left;
  }
.right-align {
    text-align: right
  }
.center, .center-align {
    text-align: center;
  }

.left {
    float: left !important;
  }
.right {
    float: right !important;
  }

// No Text Select
// =====================================
.no-select {
    user-select: none;
  }

.uppercase {
  text-transform: uppercase;
  }

.rounded {
    border-radius: #{$card-border-radius};
  }

.circle {
    border-radius: 50%;
  }

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

.truncate,
.truncate > p {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.no-padding {
    padding: 0 !important;
  }

// Z-levels
// =============================================================

.z-depth-0 {
  box-shadow: none !important;
}
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.z-depth-1-half {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}
.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.3);
}
.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.3);
}
.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}
.hoverable {
  transition: box-shadow .25s;
  &:hover {
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}

.eval {
  margin-right: 15px;
  .material-icons {
    margin-right: -18px;
  }
}

// CONTACT
// =============================================================
.coordonnees {
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    list-style: none !important;
    > span {
      &.material-icons {
        flex-shrink: 0;
        margin-right: 1.5rem;
      }
    }
  }
}

.contact-page {

  h1 {
    color: #{$background-color};
    font-size: 2rem;
  }

  p.intro {
    color: #{$primary-color};
    span {
      color: #{$background-color};
    }
  }

  #fil_ariane {
    color: #{$background-color};
    a {
      color: #{$primary-color};
    } 
  }

  .coordonnees {
    li {
      padding: .5rem 0;
      font-size: .875rem; 
      color: #{$background-color};
    }
  }

  #clock {  
    li {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      padding: .5rem 0;
      font-size: .875rem;  
      > span {
        &.material-icons {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          height: auto !important;
          margin-right: 1.5rem;
        }
      }
      table {
        color: #{$background-color};
      }
    }
  }

  #infos-pratiques {
    background: #{$primary-background-color};
    padding: 2rem;
    margin-top: 2rem;
    border: 1px solid #{$primary-color};
    li {
      color: #{$primary-color} !important;
    }
    .title-2 {
      color: #{$secondary-color};
    }
    .infos-content {
      display: flex;
      margin-bottom: 1rem;  
    }
    div {
      margin-left: 1rem; 
      strong {
        color: #{$secondary-color};
      }
      p {
        color: #{$text-color};
        margin-bottom: 0;
      }
    }
  }

  .map-container {
    @media #{$extra-large-and-up} {
      iframe {
        height: 710px;
      }
    }
  }

}

form {
  &.generateur {
    p.submit {
      margin-left: 0 !important;
    }
  }
}

.plan-site {
  .col-plan {
    ul {
      margin-left: 0;
      li {
        &.firstLi {
          list-style: none;
          a[href='#'] {
            color: inherit !important;
            text-decoration: none !important;
            opacity: .4;
            pointer-events: none !important;
            &::after {
              content: '\00A0 :';
            }
          }
        }
        &.sousLi {
          list-style: inside;
        }
        &.sousSousLi {
          list-style: circle inside;
          margin-left: 1.5rem;
        }
        a {
          color: #{$secondary-color};
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

// INPUT NEWSLETTER
// ========================================================
.newsletterInput {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  @media #{$large-and-up} {
    display: block;
    #js_submit {
      color: #{$primary-color} !important;
      border-color: #{$primary-color} !important;
      background-color: transparent !important;
      position: absolute;
      top: 0;
      right: .5rem;
      &:hover {
        color: #FFFFFF !important;
        border-color: #{$primary-color} !important;
        background-color: #{$primary-color} !important;
      }
    }
    #input_newsletter {
      padding-right: 148px;
    }
  }
  #input_newsletter {
    margin-bottom: 0;
    height: 68px;
    height: calc(#{$button-height} + 1rem);
    color: #{$text-color};
    background-color: #FFFFFF;
  }
  #js_submit {
    @extend .btn-3;
    margin-top: .5rem;
    font: {
      family: inherit;
      size: $button-font-size;
    }
    cursor: pointer;
  }
}

// Slick
// ========================================================
.slick-slicker {
  position: relative;
  z-index: 0;
}

.slick-slicker {
  .slick-arrow {
    &::before {
      color: #{$text-color};
    }
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slide {
    outline: none !important;
    a {
      outline: none !important;
    }
}

@media #{$medium-and-down} {
  .slick-list {
    overflow: visible !important;
  }
}

// Sticky
// ===================================================
.sticky {
  @media #{$large-and-up} {
    width: 100%;
    background-color: #{$background-color};
    position: fixed;
    top: 0;
    z-index: 10;
  }
}